<template>
  <main class="point-report">
    <PageTitle title="點數報表" hideBtn />
    <YearStatistic />
    <MemberPointBalance />
    <PointRecordTable />
  </main>
</template>

<script setup>
import PointRecordTable from './components/PointRecordTable.vue'
import MemberPointBalance from './components/MemberPointBalance.vue'
import YearStatistic from './components/YearStatistic.vue'
import { reactive } from 'vue'

const dialog = reactive({
  exportOptions: false,
  exporting: false,
})

</script>
